import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { KeywordInstallsModel } from '@/assets/ts/_utils/models/KeywordInstallsModel';
import { DeliveryTypeEnums } from '@/store/enums/DeliveryTypeEnums';
import { PromotionRuleTypeEnums } from '@/store/enums/PromotionRuleTypeEnums';
import { RateTypeEnums } from '@/store/enums/RateTypeEnums';
import { ReviewKeywordInstallModel } from '@/assets/ts/_utils/models/ReviewKeywordInstallModel';
import { ReviewModeEnums } from '@/store/enums/ReviewModeEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { AdvertisingCampaignEnums } from '@/store/enums/AdvertisingCampaignEnums';
import { CountryFilterModel } from '@/assets/ts/_utils/models/CountryFilterModel';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface AddCampaignsInfo {
    errors: unknown;
    deliveryType: unknown;
    description: unknown;
    promotionRule: unknown;
    rateType: unknown;
    deeplink: unknown;
    checkedTerms: unknown;
    reviewsCommon: unknown;
    reviews: unknown;
    addCampaign: unknown;
}

@Module({ name: 'AddCampaignsModule' })
export default class AddCampaignsModule extends VuexModule implements AddCampaignsInfo, StoreModel {
  errors = {};
  deliveryType = DeliveryTypeEnums.INSTANT;
  promotionRule = PromotionRuleTypeEnums.Rule0;
  rateType = RateTypeEnums.POSITIVE_AVERAGE;
  reviewMode = ReviewModeEnums.CUSTOM as number;
  reviewsCommon = [];
  cpiBudget = AdvertisingCampaignEnums.DAILY_BUDGET_VALUE;
  cpiTarget = AdvertisingCampaignEnums.TARGET_CPI_VALUE;
  cpiDays: number = AdvertisingCampaignEnums.DAYS_IN_CAMPAIGN_VALUE;
  checkboxUnlimited: boolean = false;
  partner = 0;
  checkedTerms = false;
  serializedChildKeywords = [];
  reviews = [] as ReviewKeywordInstallModel[];
  description = '';
  cpiHeadline1 = '';
  cpiHeadline2 = '';
  deeplink = '';
  addCampaign = '';
  cpiAdAssets = '';
  cpiCountry = [];
  gender = 0;
  rateInstalls = {} as KeywordInstallsModel;
  reviewInstalls = {} as KeywordInstallsModel;
  cpiInstalls = {} as KeywordInstallsModel;
  hqInstalls = {} as KeywordInstallsModel;
  checkboxValues = {
    CPI: {
      high: false,
      medium: false,
      low: false,
    },
    GEO: {
      europe: false,
      asia: false,
      africa: false,
      latinAmerica: false,
      worldwide: false,
    },
    LANGUAGE: {
      english: false,
      spanish: false,
      russian: false,
    }
  } as CountryFilterModel;
  defaultCheckboxValues = {
    CPI: {
      high: false,
      medium: false,
      low: false,
    },
    GEO: {
      europe: false,
      asia: false,
      africa: false,
      latinAmerica: false,
      worldwide: false,
    },
    LANGUAGE: {
      english: false,
      spanish: false,
      russian: false,
    }
  } as CountryFilterModel;

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ADD_CAMPAIGNS]() {
    return this.errors;
  }

  /**
     * Get reviews
     * @returns ReviewKeywordInstallModel[]
     */
  get [Getters.GET_REVIEWS_ADD_CAMPAIGNS](): ReviewKeywordInstallModel[] {
    return this.reviews;
  }

  /**
     * Get reviews common
     * @returns ReviewKeywordInstallModel[]
     */
  get [Getters.GET_REVIEWS_COMMON_ADD_CAMPAIGNS](): ReviewKeywordInstallModel[] {
    return this.reviewsCommon;
  }

  /**
     * Get deeplink
     * @returns deeplink
     */
  get [Getters.GET_DEEPLINK]() {
    return this.deeplink;
  }

  /**
     * Get cpiCountry
     * @returns cpiCountry
     */
  get [Getters.GET_CPI_COUNTRY]() {
    return this.cpiCountry;
  }

  /**
     * Get cpiTarget
     * @returns cpiTarget
     */
  get [Getters.GET_CPI_TARGET]() {
    return this.cpiTarget;
  }

  /**
     * Get cpiBudget
     * @returns cpiBudget
     */
  get [Getters.GET_CPI_BUDGET]() {
    return this.cpiBudget;
  }

  get [Getters.GET_SERIALIZED_CHILD_KEYWORDS]() {
    return this.serializedChildKeywords;
  }

  /**
     * Get cpiBudget
     * @returns cpiBudget
     */
  get [Getters.GET_CPI_DAYS]() {
    return this.cpiDays;
  }

  /**
     * Get cpiHeadline1
     * @returns cpiHeadline1
     */
  get [Getters.GET_CPI_HEADLINE_1]() {
    return this.cpiHeadline1;
  }

  /**
     * Get cpiHeadline2
     * @returns cpiHeadline2
     */
  get [Getters.GET_CPI_HEADLINE_2]() {
    return this.cpiHeadline2;
  }

  /**
     * Get cpiAdAssets
     * @returns cpiAdAssets
     */
  get [Getters.GET_CPI_AD_ASSETS]() {
    return this.cpiAdAssets;
  }

  /**
     * Get reviewMode
     * @returns reviewMode
     */
  get [Getters.GET_REVIEW_MODE]() {
    return this.reviewMode;
  }

  /**
     * Get gender
     * @returns gender
     */
  get [Getters.GET_GENDER]() {
    return this.gender;
  }

  /**
     * Get partner
     * @returns partner
     */
  get [Getters.GET_PARTNER]() {
    return this.partner;
  }

  /**
     * Get checkedTerms
     * @returns checkedTerms
     */
  get [Getters.GET_CHECKED_TERMS]() {
    return this.checkedTerms;
  }


  /**
     * Get current addCampaign
     * @returns addCampaign
     */
  get [Getters.GET_ADD_CAMPAIGNS]() {
    return this.addCampaign;
  }

  /**
     * Get current deliveryType
     * @returns deliveryType
     */
  get [Getters.GET_DELIVERY_TYPE]() {
    return this.deliveryType;
  }

  /**
     * Get current rateType
     * @returns rateType
     */
  get [Getters.GET_RATE_TYPE]() {
    return this.rateType;
  }

  /**
     * Get current description
     * @returns description
     */
  get [Getters.GET_DESCRIPTION_ADD_CAMPAIGNS]() {
    return this.description;
  }

  /**
     * Get current promotionRule
     * @returns promotionRule
     */
  get [Getters.GET_PROMOTION_RULE]() {
    return this.promotionRule;
  }


  /**
     * Get current hqInstalls
     * @returns KeywordInstallsModel
     */
  get [Getters.GET_HQINSTALLS](): KeywordInstallsModel {
    return this.hqInstalls;
  }

  /**
     * Get current reviewInstalls
     * @returns KeywordInstallsModel
     */
  get [Getters.GET_REVIEW_INSTALLS](): KeywordInstallsModel {
    return this.reviewInstalls;
  }

  /**
     * Get current rateInstalls
     * @returns KeywordInstallsModel
     */
  get [Getters.GET_RATE_INSTALLS](): KeywordInstallsModel {
    return this.rateInstalls;
  }

  /**
     * Get current cpiInstalls
     * @returns KeywordInstallsModel
     */
  get [Getters.GET_CPI_INSTALLS](): KeywordInstallsModel {
    return this.cpiInstalls;
  }

  get [Getters.GET_CHECKBOX_VALUES]() {
    return this.checkboxValues;
  }

  get [Getters.GET_DEFAULT_CHECKBOX_VALUES]() {
    return this.defaultCheckboxValues;
  }

  get [Getters.GET_CHECKBOX_UNLIMITED]() {
    return this.checkboxUnlimited;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.deliveryType = DeliveryTypeEnums.INSTANT;
    this.promotionRule = PromotionRuleTypeEnums.Rule0;
    this.rateType = RateTypeEnums.POSITIVE_AVERAGE;
    this.reviewMode = ReviewModeEnums.CUSTOM as number;
    this.reviewsCommon = [];
    this.cpiCountry = [];
    this.partner = 0;
    this.cpiBudget = AdvertisingCampaignEnums.DAILY_BUDGET_VALUE;
    this.cpiTarget = AdvertisingCampaignEnums.TARGET_CPI_VALUE;
    this.cpiDays = AdvertisingCampaignEnums.DAYS_IN_CAMPAIGN_VALUE;
    this.checkedTerms = false;
    this.serializedChildKeywords = [];
    this.reviews = [] as ReviewKeywordInstallModel[];
    this.description = '';
    this.cpiHeadline2 = '';
    this.cpiHeadline1 = '';
    this.deeplink = '';
    this.addCampaign = '';
    this.cpiAdAssets = '';
    this.gender = 0;
    this.rateInstalls = {} as KeywordInstallsModel;
    this.reviewInstalls = {} as KeywordInstallsModel;
    this.cpiInstalls = {} as KeywordInstallsModel;
    this.hqInstalls = {} as KeywordInstallsModel;
    this.checkboxValues = {
      CPI: {
        high: false,
        medium: false,
        low: false,
      },
      GEO: {
        europe: false,
        asia: false,
        africa: false,
        latinAmerica: false,
        worldwide: false,
      },
      LANGUAGE: {
        english: false,
        spanish: false,
        russian: false,
      }
    } as CountryFilterModel;
  };

    @Mutation
    [Mutations.SET_SERIALIZED_CHILD_KEYWORDS](payload) {
      this.serializedChildKeywords = payload;
    }

    @Mutation
    [Mutations.SET_CHECKBOX_VALUES](value) {
      this.checkboxValues = value;
    }

    @Mutation
    [Mutations.SET_REVIEW_MODE_ADD_CAMPAIGNS](reviewMode) {
      this.reviewMode = reviewMode;
    }

    @Mutation
    [Mutations.SET_GENDER_ADD_CAMPAIGNS](gender) {
      this.gender = gender;
    }

    @Mutation
    [Mutations.SET_REVIEWS_ADD_CAMPAIGNS](reviews) {
      this.reviews = reviews;
    }

    @Mutation
    [Mutations.SET_CPI_BUDGET_ADD_CAMPAIGNS](cpiBudget) {
      this.cpiBudget = cpiBudget;
    }

    @Mutation
    [Mutations.SET_CPI_TARGET_ADD_CAMPAIGNS](cpiTarget) {
      this.cpiTarget = cpiTarget;
    }

    @Mutation
    [Mutations.SET_CPI_COUNTRY_ADD_CAMPAIGNS](cpiCountry) {
      this.cpiCountry = cpiCountry;
    }

    @Mutation
    [Mutations.SET_CPI_HEADLINE1_ADD_CAMPAIGNS](cpiHeadline1) {
      this.cpiHeadline1 = cpiHeadline1;
    }

    @Mutation
    [Mutations.SET_CPI_HEADLINE2_ADD_CAMPAIGNS](cpiHeadline2) {
      this.cpiHeadline2 = cpiHeadline2;
    }

    @Mutation
    [Mutations.SET_CPI_DAYS_ADD_CAMPAIGNS](cpiDays) {
      this.cpiDays = cpiDays;
    }


    @Mutation
    [Mutations.SET_PARTNER_ADD_CAMPAIGNS](partner) {
      this.partner = partner;
    }

    @Mutation
    [Mutations.REMOVE_REVIEWS_ADD_CAMPAIGNS](id) {
      this.reviews = this.reviews.filter((el) => {
        if (el.id !== id) {
          return el;
        }
      }) as ReviewKeywordInstallModel[];
    }

    @Mutation
    [Mutations.SET_DEEPLINK](deeplink) {
      this.deeplink = deeplink;
    }

    @Mutation
    [Mutations.SET_CHECKED_TERMS](checkedTerms) {
      this.checkedTerms = checkedTerms;
    }

    @Mutation
    [Mutations.SET_DELIVERY_TYPE](deliveryType) {
      this.deliveryType = deliveryType;
    }

    @Mutation
    [Mutations.SET_ADD_CAMPAIGN](addCampaign) {
      this.addCampaign = addCampaign;
    }

    @Mutation
    [Mutations.SET_PROMOTION_RULE](promotionRule) {
      this.promotionRule = promotionRule;
    }

    @Mutation
    [Mutations.SET_RATE_TYPE](rateType) {
      this.rateType = rateType;
    }

    @Mutation
    [Mutations.SET_DESCRIPTION_ADD_CAMPAIGNS](description) {
      this.description = description;
    }

    @Mutation
    [Mutations.SET_CPI_INSTALLS](cpiInstalls) {
      this.cpiInstalls = cpiInstalls;
    }

    @Mutation
    [Mutations.SET_HQ_REVIEW_INSTALLS](hqInstalls) {
      this.hqInstalls = hqInstalls;
    }

    @Mutation
    [Mutations.SET_RATE_INSTALLS](rateInstalls) {
      this.rateInstalls = rateInstalls;
    }

    @Mutation
    [Mutations.SET_REVIEW_INSTALLS](reviewInstalls) {
      this.reviewInstalls = reviewInstalls;
    }


    @Mutation
    [Mutations.SET_REVIEWS_COMMON](reviewsCommon) {
      this.reviewsCommon = reviewsCommon;
    }

    @Mutation
    [Mutations.SET_CPI_AD_ASSETS](cpiAdAssets) {
      this.cpiAdAssets = cpiAdAssets;
    }

    @Mutation
    [Mutations.SET_CHECKBOX_UNLIMITED](checkboxUnlimited) {
      this.checkboxUnlimited = checkboxUnlimited;
    }

    @Mutation
    [Mutations.SET_ERROR_ADD_CAMPAIGNS](error) {
      this.errors = { ...error };
    }


    @Action
    [Actions.ADD_CAMPAIGNS](params) {
      return ApiService.post(`${domainUrl}campaigns`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADD_CAMPAIGN, data.data);
          this.context.commit(Mutations.SET_CAMPAIGN_ID_TOP_FILTER, data.data.id);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADD_CAMPAIGNS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_REVIEWS_COMMON](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}reviews/common`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REVIEWS_COMMON, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADD_CAMPAIGNS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CPI_AD_ASSETS_ACTION](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}campaigns/cpi-ad-assets`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CPI_AD_ASSETS, data.data.text);
          return data.data.text;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADD_CAMPAIGNS, response?.data?.errors);
        });
    }
}
